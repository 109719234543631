

















































































import { Vue, Provide, Component } from "vue-property-decorator";
import { Toast } from "vant";
import serve from "@/serve/home.ts";
interface Type {
  key: string;
}
@Component({})
export default class SearchGoods extends Vue {
  goodsData: Array<object> = [];
  QQdata=[]
  showDia = false
  @Provide() searchType = "商品";
  @Provide() img = require("@/assets/img/store.jpg");
  @Provide() goods = require("@/assets/img/goods.jpg");
  @Provide() keyword = "";
  @Provide() page = 0;
  @Provide() size = 5;
  // @Provide() goodsData = [];
  @Provide() show = false;
  @Provide() loading = false; //是否处于加载状态
  @Provide() finished = false; //是否已加载完所有数据
  @Provide() isLoading = false; //是否处于下拉刷新状态
  @Provide() columns = [
    {
      key: "商品",
    },
    {
      key: "店铺",
    },
  ];
  created() {
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword.toString();
      // this.getMerchandise(this.keyword);
    }
  }
  private onCancel(): void {
    this.show = false;
  }

  private onConfirm(val: Type): void {
    this.searchType = val.key;
    this.show = false;
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
  private search(): void {
    if (this.searchType == "店铺") {
      this.$router.push(`/searchStore?keyword=${this.keyword}`)
    } else {
      this.page = 0;
      this.size = 5;
      this.getMerchandise(this.keyword);
    }
  }

  async getMerchandise(val: string) {
    this.loading = true;
    const res = await serve.getMerchandise({
      keyword: val,
      page: this.page,
      size: this.size,
    });
    if (res.data.code === 0) {
      res.data.data.content.map((item) => {
        item.pic = item.pictures.split(",");
        // this.goodsData.push(item);
      });
       this.goodsData=this.goodsData.concat(res.data.data.content)
      this.loading = false;
      this.isLoading = false;
      if (res.data.data.totalElements <= this.goodsData.length) {
        this.finished = true;
      }else {
        this.finished = false
      }
    } else {
      Toast(res.data.message);
    }
  }
  chooseQQ(val: object): void {
    this.QQdata = val['qq']
    this.showDia  = true
  }
  openQQ(val?: string): void{
    window.location.href=`mqqwpa://im/chat?chat_type=wpa&uin=${val}&version=1&src_type=web&web_src=oicqzone.com`
  }
  private onLoad(): void {
    // 上拉加载
    this.loading = true;
    this.getMerchandise(this.keyword);
    this.page ++
  }

  private onRefresh(): void {
    //下拉刷新
    this.goodsData = [];
    this.isLoading = true;
    this.page = 0;
    this.size = 5;
    this.getMerchandise(this.keyword);
  }
}
