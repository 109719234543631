import { http } from '@/utils/http';
export default {
    getCarousel(){
        // 获取banner图
        return http({
            url:`/members/api/v1/carousel`,
            method:'GET'
        })
    },
    getShops(params: object){
        // 获取推荐商家
        return http({
            url:'/members/api/v1/shops',
            method:'get',
            params:params
        })
    },
    collectShop(params: string){
        // 收藏店铺
        return http({
            url:`/members/api/v1/shops/${params}/collection`,
            method:'post'
        })
    },
    cancleCollect(params: string){
        // 收藏店铺
        return http({
            url:`/members/api/v1/shops/${params}/collection`,
            method:'delete'
        })
    },
    getMerchandise(params: object){
        // 获取推荐商品
        return http({
            url:`/members/api/v1/merchandise`,
            method:'get',
            params:params
        })
    },
    
}